import { createSlice } from '@reduxjs/toolkit';

// Define the initial state for the loader
const initialState = {
  isLoaderShown: false, // The loader should be shown on first visit
};

// Create a Redux slice for the loader
const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    // Action to mark that the loader has been shown
    setLoaderShown(state) {
      state.isLoaderShown = true;
    },
  },
});

// Export the action to set the loader state
export const { setLoaderShown } = loaderSlice.actions;

// Export the reducer to be used in the store
export default loaderSlice.reducer;
