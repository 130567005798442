import "@fontsource/dm-sans"
import "@fontsource/dm-sans/500.css"
import "@fontsource/dm-sans/700.css"
import "@fontsource/dm-mono"
import "@fontsource/dm-mono/500.css"
import React from 'react';
import { Provider } from 'react-redux';
import store from './src/state/store'; // Path to your store

export const wrapRootElement = ({ element }) => (
  <Provider store={store}>{element}</Provider>
);
